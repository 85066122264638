<template>
  <div class="layout" :class="isEditMode ? 'single-column' : 'two-column'">
    <div class="main-content">
      <h2>
        {{ isNewAccount
          ? 'Create New Account'
          : isEditMode
          ? 'Edit Account'
          : account.name }}
      </h2>

      <form v-if="isEditMode" @submit.prevent="saveAccount">
        <!-- Corporation Name -->
        <div class="form-group">
          <label for="corporationName">Corporation Name</label>
          <input type="text" id="corporationName" v-model="account.name" required />
        </div>

        <!-- Type of Corporation -->
        <div class="form-group">
          <label for="typeOfCorporation">Type of Corporation</label>
          <select id="typeOfCorporation" v-model="account.typeOfCorporation" required>
            <option value="">Select Type</option>
            <option value="Provincial">Provincial</option>
            <option value="Federal">Federal</option>
          </select>
        </div>

        <!-- Nature of Business -->
        <div class="form-group">
          <label for="natureOfBusiness">Nature of Business</label>
          <select id="natureOfBusiness" v-model="account.natureOfBusiness" required>
            <option value="">Select Nature</option>
            <option v-for="business in natureOfBusinessOptions" :key="business" :value="business">
              {{ business }}
            </option>
          </select>
        </div>

        <!-- GST/HST Number -->
        <div class="form-group">
          <label for="gstHstNumber">GST/HST Number</label>
          <input type="text" id="gstHstNumber" v-model="account.gstHstNumber" required />
        </div>

        <!-- GST/HST Filing Frequency -->
        <div class="form-group">
          <label for="gstHstFilingFrequency">GST/HST Filing Frequency</label>
          <select id="gstHstFilingFrequency" v-model="account.gstHstFilingFrequency" required>
            <option value="">Select Frequency</option>
            <option v-for="frequency in validFrequencies" :key="frequency" :value="frequency">
              {{ frequency }}
            </option>
          </select>
        </div>

        <!-- Tax Year End (Month) -->
        <div class="form-group">
          <label for="taxYearEnd">Tax Year End (Month)</label>
          <select id="taxYearEnd" v-model="account.taxYearEnd" required>
            <option value="">Select Month</option>
            <option v-for="month in validMonths" :key="month" :value="month">
              {{ month }}
            </option>
          </select>
        </div>

        <!-- Address Line 1 -->
        <div class="form-group">
          <label for="addressLine1">Address Line 1</label>
          <input type="text" id="addressLine1" v-model="account.addressLine1" />
        </div>

        <!-- Address Line 2 -->
        <div class="form-group">
          <label for="addressLine2">Address Line 2</label>
          <input type="text" id="addressLine2" v-model="account.addressLine2" />
        </div>

        <!-- City -->
        <div class="form-group">
          <label for="city">City</label>
          <input type="text" id="city" v-model="account.city" />
        </div>

        <!-- Province/State -->
        <div class="form-group">
          <label for="province">Province/State</label>
          <input type="text" id="province" v-model="account.province" />
        </div>

        <!-- Postal Code -->
        <div class="form-group">
          <label for="postalCode">Postal Code</label>
          <input type="text" id="postalCode" v-model="account.postalCode" />
        </div>

        <!-- Country -->
        <div class="form-group">
          <label for="country">Country</label>
          <input type="text" id="country" v-model="account.country" />
        </div>

        <!-- Mobile Number -->
        <div class="form-group">
          <label for="mobileNumber">Mobile Number</label>
          <input
            type="tel"
            id="mobileNumber"
            v-model="account.mobileNumber"
            placeholder="Enter mobile number"
            @input="formatPhoneNumber('mobileNumber')"
          />
          <span v-if="mobileNumberError" class="error-message">{{ mobileNumberError }}</span>
        </div>

        <!-- Office Number -->
        <div class="form-group">
          <label for="officeNumber">Office Number</label>
          <input
            type="tel"
            id="officeNumber"
            v-model="account.officeNumber"
            placeholder="Enter office number"
            @input="formatPhoneNumber('officeNumber')"
          />
          <span v-if="officeNumberError" class="error-message">{{ officeNumberError }}</span>
        </div>

        <!-- Name of Owner -->
        <div class="form-group">
          <label for="owner">Name of Owner</label>
          <input type="text" id="owner" v-model="account.owner" />
        </div>

        <!-- Is Active Customer -->
        <div class="form-group">
          <label for="isActive">Is Active Customer</label>
          <input type="checkbox" id="isActive" v-model="account.isActive" />
        </div>

        <!-- Submit Button -->
        <div>
          <button type="submit" class="btn-primary" :disabled="isSaving">
            {{ isSaving ? 'Saving...' : isNewAccount ? 'Create Account' : 'Save Account' }}
          </button>
        </div>

        <!-- Error Message -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>

      <!-- View Mode -->
      <div v-else>
        <p><strong>Corporation Name:</strong> {{ account.name }}</p>
        <p><strong>Type of Corporation:</strong> {{ account.typeOfCorporation }}</p>
        <p><strong>Nature of Business:</strong> {{ account.natureOfBusiness }}</p>
        <p><strong>GST/HST Number:</strong> {{ account.gstHstNumber }}</p>
        <p><strong>GST/HST Filing Frequency:</strong> {{ account.gstHstFilingFrequency }}</p>
        <p><strong>Tax Year End (Month):</strong> {{ account.taxYearEnd }}</p>
        <p><strong>Address Line 1:</strong> {{ account.addressLine1 }}</p>
        <p><strong>Address Line 2:</strong> {{ account.addressLine2 }}</p>
        <p><strong>City:</strong> {{ account.city }}</p>
        <p><strong>Province/State:</strong> {{ account.province }}</p>
        <p><strong>Postal Code:</strong> {{ account.postalCode }}</p>
        <p><strong>Country:</strong> {{ account.country }}</p>
        <p><strong>Mobile Number:</strong> {{ account.mobileNumber }}</p>
        <p><strong>Office Number:</strong> {{ account.officeNumber }}</p>
        <p><strong>Name of Owner:</strong> {{ account.owner }}</p>
        <p><strong>Is Active Customer:</strong> {{ account.isActive ? 'Yes' : 'No' }}</p>

        <button class="btn-primary" @click="editAccount">Edit Account</button>
      </div>
    </div>

    <!-- Right Column (Tax Return List) -->
    <div class="related-content" v-if="!isNewAccount && !isEditMode">
      <TaxReturnList :accountId="accountId" />
      <button class="btn-secondary" @click="addTaxReturn">Add New Tax Return</button>
    </div>
  </div>
</template>

<script>
import TaxReturnList from './TaxReturnList.vue';
import { ref, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Import the Pinia store

export default {
  components: { TaxReturnList },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const accountId = route.params.id;
    const isNewAccount = ref(!accountId);
    const account = ref({
      mobileNumber: '',
      officeNumber: '',});
    const isEditMode = ref(true); // Default to edit mode
    const isSaving = ref(false); // Track saving state
    const errorMessage = ref(null); // Error message for handling errors
    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const userStore = useUserStore(); // Initialize Pinia user store

    // Define valid months (using abbreviations)
    const validMonths = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    const validFrequencies = ['Monthly', 'Quarterly', 'Annually'];
    const fullMonthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const mobileNumberError = ref('');
    const officeNumberError = ref('');

    const formatPhoneNumber = (field) => {
      let input = account.value[field];

      // Remove all non-digit characters
      input = input.replace(/\D/g, '');

      // Limit input to maximum of 10 digits
      if (input.length > 10) {
        input = input.slice(0, 10);
      }

      // Format based on the number of digits entered
      if (input.length === 0) {
        account.value[field] = '';
      } else if (input.length < 4) {
        account.value[field] = input;
      } else if (input.length < 7) {
        account.value[field] = `(${input.slice(0, 3)}) ${input.slice(3)}`;
      } else if (input.length <= 9) {
        account.value[field] = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`;
      } else {
        account.value[field] = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
      }

      // Validate the formatted phone number
      validatePhoneNumber(field);
    };

    const validatePhoneNumber = (field) => {
      const phoneNumber = account.value[field];
      const phoneRegex9Digits = /^\(\d{3}\) \d{3}-\d{3}$/; // For 9-digit numbers
      const phoneRegex10Digits = /^\(\d{3}\) \d{3}-\d{4}$/; // For 10-digit numbers

      if (phoneNumber === null || phoneNumber === '') {
        if (field === 'mobileNumber') {
          mobileNumberError.value = '';
        } else if (field === 'officeNumber') {
          officeNumberError.value = '';
        }
        return;
      }

      if (phoneRegex9Digits.test(phoneNumber) || phoneRegex10Digits.test(phoneNumber)) {
        if (field === 'mobileNumber') {
          mobileNumberError.value = '';
        } else if (field === 'officeNumber') {
          officeNumberError.value = '';
        }
      } else {
        if (field === 'mobileNumber') {
          mobileNumberError.value = 'Invalid mobile number format.';
        } else if (field === 'officeNumber') {
          officeNumberError.value = 'Invalid office number format.';
        }
      }
    };

    // Fetch account details
    const fetchAccountDetails = async () => {
      errorMessage.value = null; // Clear error message
      if (isNewAccount.value) {
        // Initialize account with default values if needed
        account.value = {
          isActive: true,
          // Add other default values as needed
        };
      } else {        
        const { sessionId, authToken } = userStore;
        let response;
        try {
          console.log('accountForm:fetchAccountDetails:isNewAccout: apiUrl:', apiUrl);
          console.log('accountForm:fetchAccountDetails:isNewAccout: accountId:', `${accountId}`);
          response = await fetch(`${apiUrl}/api/accounts/${accountId}`, {
            headers: {
              'x-Session-id': sessionId, // Add the session ID header
              'Authorization': authToken ? `Bearer ${authToken}` : '', // Optional auth token
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });
          if (response.ok) {
            account.value = await response.json();
            isEditMode.value = false; // Switch to view mode after loading existing account
          } else {
            errorMessage.value = 'Error fetching account details.';
            const responseText = await response.text();
            console.error('AccountForm: Raw response body:', responseText);
          }
        } catch (error) {
          errorMessage.value = 'Error fetching account details.';
          console.error('AccountForm: Error fetching account details.1.');
        }
      }
    };

    // Validate form before saving
    const validateForm = () => {
      errorMessage.value = null; // Reset the error message

      if (!account.value.name) {
        errorMessage.value = 'Corporation Name is required.';
        return false;
      }
      if (!account.value.gstHstNumber) {
        errorMessage.value = 'GST/HST Number is required.';
        return false;
      }
      // Validate GST/HST Filing Frequency
      if (
        !account.value.gstHstFilingFrequency ||
        !validFrequencies.includes(account.value.gstHstFilingFrequency)
      ) {
        errorMessage.value =
          'Please select a valid GST/HST Filing Frequency: Monthly, Quarterly, or Annually.';
        return false;
      }
      // Validate Tax Year End
      if (
        !account.value.taxYearEnd ||
        !validMonths.includes(account.value.taxYearEnd)
      ) {
        errorMessage.value = 'Please select a valid Tax Year End month.';
        return false;
      }

      // Phone number regex matching (123) 456-7890
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

      // Validate Mobile Number (if provided)
      if (account.value.mobileNumber && !phoneRegex.test(account.value.mobileNumber)) {
        errorMessage.value = 'Please enter a valid mobile number in the format (123) 456-7890.';
        return false;
      }

      // Validate Office Number (if provided)
      if (account.value.officeNumber && !phoneRegex.test(account.value.officeNumber)) {
        errorMessage.value = 'Please enter a valid office number in the format (123) 456-7890.';
        return false;
      }

      if (mobileNumberError.value) {
        errorMessage.value = mobileNumberError.value;
        return false;
      }
      // Validate Office Number (if provided)
      if (officeNumberError.value) {
        errorMessage.value = officeNumberError.value;
        return false;
      }

      return true;
    };

    // Save account details
    const saveAccount = async () => {
      if (!validateForm()) return;

      isSaving.value = true; // Set saving state
      errorMessage.value = null;
      const { sessionId, authToken } = userStore;
      try {
        const method = isNewAccount.value ? 'POST' : 'PUT';
        const url = isNewAccount.value
          ? `${apiUrl}/api/accounts`
          : `${apiUrl}/api/accounts/${accountId}`;
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': sessionId, // Correct header
            'Authorization': authToken ? `Bearer ${authToken}` : '', // Include auth token
          },
          credentials: 'include',
          body: JSON.stringify(account.value),
        });

        let result;
        try {
          // Try to parse the response as JSON
          result = await response.json();
        } catch (parseError) {
          console.error('Failed to parse response JSON:', parseError);
          errorMessage.value = 'Unexpected server response. Please try again later.';
          return;
        }

        if (response.ok) {
          alert('Account saved successfully');
          accountStore.fetchAccounts();
          if (isNewAccount.value) {
            // After creating a new account, redirect to its page
            router.push({ name: 'AccountForm', params: { id: result._id } });
          } else {
            isEditMode.value = false; // Switch to view mode after saving
          }
        } else {
          // Display the error message from the backend
          errorMessage.value = result.message || 'Error saving account.';
        }
      } catch (error) {
        console.error('Error in saveAccount:', error);
        errorMessage.value = error.message || 'Error saving account.';
      } finally {
        isSaving.value = false; // Reset saving state
      }
    };

    const editAccount = () => {
      isEditMode.value = true; // Switch to edit mode
    };

    const addTaxReturn = () => {
      console.log('accountForm.addTaxReturn: Account ID:', accountId);
      router.push({
        name: 'TaxReturnDetail',
        params: { id: accountId },
        query: { editMode: true, new: true }, // Ensure this route exists in the router
      });
    };

    onMounted(() => {
      fetchAccountDetails();
    });

    const natureOfBusinessOptions = [
  'Auto Mechanic', 'Construction', 'Consultants IT and Others', 'Driver',
  'Driving School', 'Electrical', 'Gas Station', 'Hair Salon', 'Janitorial Service',
  'Landscaping', 'Liquor Store', 'Mac Store', 'Medical Practitioner',
  'Non-Profit Organization', 'Owner Operator', 'Pipe Fitter', 'Restaurant',
  'Retail Store', 'Safety', 'Sole Proprietorship', 'Truck Driver', 'Welder', 'Other'
];

    return {
      accountId,
      account,
      isNewAccount,
      isEditMode,
      saveAccount,
      editAccount,
      addTaxReturn,
      isSaving,
      errorMessage,
      validateForm,
      validMonths,
      validFrequencies,
      formatPhoneNumber,
      mobileNumberError,
      officeNumberError,
      natureOfBusinessOptions,
    };
  },
};
</script>