<template>
    <div class="layout-wrapper">
      <slot></slot> <!-- Default slot for non-named content -->
      <div class="layout">
        <div class="main-content">
          <slot name="main"></slot>
        </div>
        <div class="related-content">
          <slot name="related"></slot>
        </div>
      </div>
    </div>
  </template>
  