// frontend/src/stores/accountStore.js
import { defineStore } from 'pinia';
import debounce from 'lodash.debounce';
import { useUserStore } from './userStore';
const apiUrl = import.meta.env.VITE_API_URL || 'https://localhost:3000';

export const useAccountStore = defineStore('account', {

  state: () => ({
    accounts: [],
    loading: false,
    error: null, // Error state to store error messages
    currentPage: 1,
    totalPages: 1,
    pageSize: 20,
    filters: {
      name: '',
      natureOfBusiness: '',
      gstHstNumber: '',
      address: '',
      phoneNumber: '',
      activeStatus: '',
    },
    showSearch: false,
  }),
  actions: {

    /**
     * Toggles the visibility of the search form
     */
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    /**
     * Sets new filters and fetches accounts (debounced)
     * @param {Object} newFilters
     */
    setFilters(newFilters) {
      this.filters = { ...newFilters };
      this.currentPage = 1;
      this.fetchAccountsDebounced();
    },

    /**
     * Resets all filters and fetches accounts immediately
     */
    resetFilters() {
      this.filters = {
        name: '',
        natureOfBusiness: '',
        gstHstNumber: '',
        address: '',
        phoneNumber: '',
        activeStatus: '',
      };
      this.currentPage = 1;
      this.fetchAccounts(); // Immediate fetch
    },

    /**
     * Changes the current page and fetches accounts immediately
     * @param {Number} newPage
     */
    changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        this.fetchAccounts(); // Immediate fetch
      }
    },

    /**
     * Fetches accounts from the backend with current filters and pagination
     */
    async fetchAccounts() {
      

      this.loading = true;
      this.error = null; // Reset error state
      const queryParams = new URLSearchParams();

      // Append filters to query parameters
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value) {
          queryParams.append(key, value);
        }
      });

      // Append pagination parameters
      queryParams.append('page', this.currentPage);
      queryParams.append('limit', this.pageSize);

      try {
        const userStore = useUserStore(); // Correctly initialize userStore
        const sessionId = userStore.sessionId; // Retrieve sessionId
        const authToken = userStore?.authToken || null; // Retrieve authToken if available

        if (!sessionId) {
          console.error('Session ID is missing. Please login again.');
          this.error = 'Session ID is missing. Please login again.';
          this.loading = false;
          return;
        }

        console.log('accountStore: sessionId=' + sessionId);
        console.log('accountStore: apiUrl:', apiUrl);

        const response = await fetch(`${apiUrl}/api/accounts?${queryParams.toString()}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-session-id': sessionId, // Include session ID
            'Authorization': authToken ? `Bearer ${authToken}` : '', // Optional auth token
          },
          credentials: 'include', // Include cookies if used
        });

        console.log('Response status:', response.status);
        console.log('Response headers:', JSON.stringify([...response.headers]));

        const contentType = response.headers.get('content-type');
        const responseText = await response.text();

        //console.log('Raw response body:', responseText);

        if (response.ok && contentType && contentType.includes('application/json')) {
          const result = JSON.parse(responseText);
          console.log('Parsed JSON response:', result);
          this.accounts = result.data;
          this.totalPages = result.meta.totalPages;
        } else {
          console.error('accountStore: Unexpected content type:', contentType);
          console.error('Raw response body:', responseText);
          this.error = 'Unexpected response format or server error. Please try again.';
          this.accounts = []; // Invalidate cache
          this.totalPages = 1; // Reset total pages
        }
      } catch (error) {
        console.error('accountStore: Error fetching accounts:', error);
        this.error = 'An error occurred while fetching accounts.';
        this.accounts = [];
      } finally {
        this.loading = false;
      }
    },

    /**
     * Debounced version of fetchAccounts
     */
    fetchAccountsDebounced: debounce(async function () {
      await this.fetchAccounts();
    }, 300),

    /**
     * Deletes an account by its ID
     * @param {String} accountId
     */
    async deleteAccount(accountId) {
      try {
        const userStore = useUserStore();
        const sessionId = userStore.sessionId;
        const authToken = userStore?.authToken || null;

        if (!sessionId) {
          console.error('Session ID is missing. Please login again.');
          this.error = 'Session ID is missing. Please login again.';
          return;
        }

        const response = await fetch(`${apiUrl}/api/accounts/${accountId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-session-id': sessionId, // Include session ID
            ...(authToken && { Authorization: `Bearer ${authToken}` }),
          },
          credentials: 'include',
        });

        if (response.ok) {
          this.fetchAccounts(); // Immediate fetch to refresh the list
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete account.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        this.error = error.message;
      }
    },
  },
});
