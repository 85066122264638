import { watch } from 'vue';
import { useUserStore } from '@/stores/userStore';
import { useTaxReturnStore } from '@/stores/taxReturnStore';
import { useAccountStore } from '@/stores/accountStore';

export const watchSessionAndCompany = () => {
  const userStore = useUserStore();
  const taxReturnStore = useTaxReturnStore();
  const accountStore = useAccountStore();

  watch(
    [() => userStore.sessionId, () => userStore.companyId], // Watch both sessionId and companyId
    ([newSessionId, newCompanyId], [oldSessionId, oldCompanyId]) => {
      if (newSessionId !== oldSessionId || newCompanyId !== oldCompanyId) {
        console.log(`Session or company ID changed. Clearing previous cache.`);
        
        // Ensure we are not clearing companyId or sessionId
        if (!newCompanyId) {
            console.warn('Company ID is missing after cache clear.');
        }

        // Clear and refetch data for all stores
        taxReturnStore.clearTaxReturns();
        taxReturnStore.fetchTaxReturns();

        accountStore.clearCache();
        accountStore.fetchAccounts();

        // Add similar logic for other stores as needed
      }
    },
    { immediate: true } // Trigger immediately on initialization
  );
};
