import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Updated import
import Home from '../components/Home.vue';
import SignIn from '../components/SignIn.vue';
import SignUp from '../components/SignUp.vue';
import AccountList from '../components/AccountList.vue';
import AccountForm from '../components/AccountForm.vue';
import CompanyList from '../components/CompanyList.vue';
import Company from '../components/Company.vue';
import FileList from '../components/FileList.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import TaxReturnDetail from '../components/TaxReturnDetail.vue';
const apiUrl = import.meta.env.VITE_API_URL;

// Define routes
const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/signin', name: 'SignIn', component: SignIn },
  { path: '/signup', name: 'SignUp', component: SignUp },
  { path: '/accounts', name: 'AccountList', component: AccountList, meta: { requiresAuth: true } },
  { path: '/account/new', name: 'NewAccount', component: AccountForm, meta: { requiresAuth: true } },
  {
    path: '/accounts/:id',
    name: 'AccountForm',
    component: AccountForm,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies',
    name: 'CompanyList',
    component: CompanyList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  { path: '/company/new', name: 'NewCompany', component: Company, meta: { requiresAuth: true } },
  {
    path: '/company/:id',
    name: 'EditCompany',
    component: Company,
    props: true,
    meta: { requiresAuth: true },
  },
  { path: '/files', name: 'FileList', component: FileList, meta: { requiresAuth: true } },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/tax-returns/:id',
    name: 'TaxReturnDetail',
    component: TaxReturnDetail,
    meta: { requiresAuth: true },
    props: (route) => ({
      accountId: route.params.id,
      editMode: route.query.editMode === 'true',
    }),
  },
  {
    path: '/personal-taxes',
    name: 'PersonalTaxes',
    component: () => import('../components/PersonalTax.vue'), 
    meta: { requiresAuth: true },
  },
];

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore(); // Initialize Pinia user store

  // Function to redirect to sign-in
  const redirectToSignIn = () => {
    if (to.name !== 'SignIn' && to.name !== 'SignUp') {
      next('/signin');
    } else {
      next();
    }
  };

  try {
    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
      // If user is not authenticated, attempt to fetch session from backend
      if (!userStore.isAuthenticated) {
        // Make a request to the backend to check the session
        const response = await fetch(`${apiUrl}/api/auth/session`, {
          credentials: 'include', // Include cookies
        });
        console.log('router/index.js: Response:', response);

        if (response.ok) {
          const data = await response.json();
          const user = data.user;

          if (user && user.profile) {
            console.log('User profile:', user.profile);

            // Update the userStore with the user data
            userStore.setUser({
              email: user.email,
              profile: user.profile,
              uid: user.uid,
              companyId: user.companyId || null,
            });

            // Handle authorization based on route meta fields
            if (to.meta.requiresAdmin && user.profile !== 'System Admin') {
              console.log('Access denied: Not a System Admin');
              return next('/'); // Redirect non-admin users
            } else {
              return next(); // Allow navigation
            }
          } else {
            console.log('User profile is missing, redirecting to login.');
            return redirectToSignIn();
          }
        } else {
          console.log('Session not found, redirecting to login.');
          return redirectToSignIn();
        }
      } else {
        // User is authenticated in userStore
        // Handle authorization based on route meta fields
        if (to.meta.requiresAdmin && userStore.userProfile !== 'System Admin') {
          console.log('Access denied: Not a System Admin');
          return next('/'); // Redirect non-admin users
        } else {
          return next(); // Allow navigation
        }
      }
    } else {
      // Route does not require authentication
      return next();
    }
  } catch (error) {
    console.error('Error fetching session data:', error);
    return redirectToSignIn();
  }
});

export default router;
