<template>
  <div>
    <div v-if="companySelected">
      <TaxReturnFilterWidget v-if="!loading" :key="userStore.companyId" />
      <p v-else>Loading tax returns...</p>
    </div>
    <div v-else>
      <p>Please select a company to view tax returns.</p>
      <router-link to="/companies">Select Company</router-link>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useUserStore } from '../stores/userStore'; // Adjust the path as necessary
import TaxReturnFilterWidget from '../components/TaxReturnFilterWidget.vue';

export default {
  name: 'Home',
  components: {
    TaxReturnFilterWidget,
  },
  setup() {
    const userStore = useUserStore();
    const loading = ref(false);

    // Computed property to check if a company is selected
    const companySelected = computed(() => {
      if (!userStore.companyId) {
        console.warn('Home.vue: No companyId selected. Prompting user to select a company.');
      }
      return userStore.companyId != null && userStore.companyId !== '';
    });

    return {
      companySelected,
      userStore,
      loading,
    };
  },
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
