// stores/userStore.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null, // Holds user info including companyId and profile
    companyId: null,
    sessionId: null, // Store session ID in memory only
    // New state properties for company users
    companyUsers: [],
    loadingCompanyUsers: false,
    companyUsersError: null,
  }),
  actions: {
    // Existing actions
    setUser(userData) {
      this.user = userData;
      this.sessionId = userData?.sessionId || null; // Store session ID in memory
      this.authToken = userData?.authToken || ''; 
    },
    updateCompanyId(companyId) {
      if (this.user) {
        this.user.companyId = companyId;
      }
    },
    clearUser() {
      this.user = null;
      this.sessionId = null; // Clear session ID when user logs out
      this.companyUsers = [];
      this.loadingCompanyUsers = false;
      this.companyUsersError = null;
    },
    logout() {
      this.clearUser();
      // Add additional logout logic if needed, like API calls to invalidate the session
    },
    // New actions for company users management
    async fetchCompanyUsers() {
      console.log('useUserStore.fetchCompanyUsers: Starting...');
      this.loadingCompanyUsers = true;
      this.companyUsersError = null;
    
      const { sessionId, user } = this;
      const authToken = user?.authToken;
      const apiUrl = import.meta.env.VITE_API_URL || 'https://localhost:3000';
    
      console.log('fetchCompanyUsers: Session ID:', sessionId);
      console.log('fetchCompanyUsers: Auth Token:', authToken);
  
    try {
        if (/*!authToken ||*/ !sessionId) {
          throw new Error('Authentication details are missing. Cannot fetch company users.');
        }
    
        const companyId = this.companyId;
        if (!companyId) {
          throw new Error('Company ID is missing. Cannot fetch company users.');
        }
    
        const response = await fetch(`${apiUrl}/api/admin/companies/${companyId}/users`, {
          headers: {
            'Content-Type': 'application/json',
            'x-session-id': sessionId,
            Authorization: `Bearer ${authToken}`,
          },
          credentials: 'include',
        });
    
        if (response.ok) {
          this.companyUsers = await response.json();
          console.log('Company users fetched successfully:', this.companyUsers);
        } else {
          const errorData = await response.json().catch(() => ({}));
          this.companyUsersError = errorData.message || 'Failed to fetch company users.';
          console.error('Error fetching company users:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching company users:', error);
        this.companyUsersError = error.message || 'An error occurred while fetching company users.';
      } finally {
        this.loadingCompanyUsers = false;
      }
    }
        
    // Additional actions for managing company users can be added here
    // For example, addCompanyUser, updateCompanyUser, deleteCompanyUser
    // These methods should be prefixed appropriately to avoid conflicts
  },
  getters: {
    // Existing getters
    companyId: (state) => state.user?.companyId || null,
    userProfile: (state) => state.user?.profile || '',
    isAuthenticated: (state) => !!state.user,
    // New getters for company users
    getCompanyUsers: (state) => state.companyUsers,
    isCompanyUsersLoading: (state) => state.loadingCompanyUsers,
    companyUserError: (state) => state.companyUsersError,
  },
});
