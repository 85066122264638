import { defineStore } from 'pinia';
import { ref, computed, watch, onMounted } from 'vue';
import { useUserStore } from './userStore';

export const useTaxReturnStore = defineStore('taxReturnStore', () => {
  const userStore = useUserStore();

  const taxReturns = ref([]);
  const loading = ref(false);
  const error = ref(null);
  const filters = ref({
    returnType: '',
    taxYear: '',
    status: 'Pending',
    accountId: '',
    ownerId: '',
    dueDate: '',
  });
  const sortOptions = ref({
    sortBy: 'dueDate',
    sortOrder: 'asc',
  });
  const statuses = ['Pending', 'Filed', 'Overdue'];
  const accounts = ref([]);
  const owners = ref([]);
  const totalDocuments = ref(0);
  const totalPages = ref(1);
  const currentPage = ref(1);

  const apiUrl = import.meta.env.VITE_API_URL || 'https://localhost:3000';

  const sessionId = computed(() => userStore.sessionId);
  const companyId = computed(() => userStore.companyId);

  const fetchTaxReturns = async () => {
    const authToken = userStore?.authToken || null;

    if (!sessionId.value || !companyId.value) {
      console.error('Session ID or company ID is missing. Please login again.');
      error.value = 'Session ID or company ID is missing.';
      return;
    }

    loading.value = true;
    error.value = null;

    try {
      const queryParams = new URLSearchParams({
        companyId: companyId.value, // Ensure companyId is passed in query params
        ...filters.value,
        sortBy: sortOptions.value.sortBy,
        sortOrder: sortOptions.value.sortOrder,
        page: currentPage.value,
        limit: 10,
      }).toString();

      console.log('Fetching tax returns with query:', queryParams);

      const response = await fetch(`${apiUrl}/api/taxreturns/filter?${queryParams}`, {
        headers: {
          'x-session-id': sessionId.value,
          Authorization: authToken ? `Bearer ${authToken}` : '',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched tax returns data:', data);

        taxReturns.value = Array.isArray(data.taxReturns) ? data.taxReturns : [];
        totalDocuments.value = data.totalDocuments || taxReturns.value.length;
        totalPages.value = data.totalPages || 1;
        currentPage.value = data.currentPage || 1;
      } else {
        const errorText = await response.text();
        console.error('Error fetching tax returns:', response.statusText, errorText);
        error.value = 'Failed to load tax returns.';
        taxReturns.value = [];
      }
    } catch (err) {
      console.error('Error fetching tax returns:', err);
      error.value = 'An error occurred while fetching tax returns.';
      taxReturns.value = [];
    } finally {
      loading.value = false;
    }
  };

  const clearTaxReturns = () => {
    console.log(`Clearing tax returns for session: ${sessionId.value} and company: ${companyId.value}`);
    taxReturns.value = []; // Clear current session and company-specific tax returns
  };

  watch([sessionId, companyId], ([newSessionId, newCompanyId], [oldSessionId, oldCompanyId]) => {
    if (newSessionId !== oldSessionId || newCompanyId !== oldCompanyId) {
      console.log(`Session or company ID changed. Clearing previous cache.`);
      clearTaxReturns();
    }
  });

  const applyFilters = () => {
    currentPage.value = 1;
    fetchTaxReturns();
  };

  const resetFilters = () => {
    filters.value = {
      returnType: '',
      taxYear: '',
      status: 'Pending',
      accountId: '',
      ownerId: '',
      dueDate: '',
    };
    sortOptions.value = {
      sortBy: 'dueDate',
      sortOrder: 'asc',
    };
    currentPage.value = 1;
    fetchTaxReturns();
  };

  const toggleSort = (column) => {
    if (sortOptions.value.sortBy === column) {
      sortOptions.value.sortOrder = sortOptions.value.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      sortOptions.value.sortBy = column;
      sortOptions.value.sortOrder = 'asc';
    }
    fetchTaxReturns();
  };

  const changePage = (newPage) => {
    if (newPage < 1 || newPage > totalPages.value) return;
    currentPage.value = newPage;
    fetchTaxReturns();
  };

  onMounted(() => {
    if (sessionId.value && companyId.value) {
      console.log('Store initialized. Fetching tax returns.');
      fetchTaxReturns();
    }
  });

  return {
    taxReturns,
    loading,
    error,
    filters,
    sortOptions,
    statuses,
    accounts,
    owners,
    totalPages,
    currentPage,
    totalDocuments,
    toggleSort,
    applyFilters,
    resetFilters,
    changePage,
    fetchTaxReturns,
    clearTaxReturns,
  };
});
