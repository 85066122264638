<template>
  <div v-if="proxyReady">
    <Header />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import './styles.css';  // Import the global styles here
import { onMounted } from 'vue';
import { watchSessionAndCompany } from './utils/companyWatcher.js';

export default {
  name: 'App',
  setup() {
    // Initialize the global company watcher
    onMounted(() => {
      watchSessionAndCompany();
    });
  },
  components: {
    Header,
  },
  data() {
    return {
      proxyReady: false,
    };
  },
  created() {
    // Simulate a delay for proxy configuration (adjust as needed)
    setTimeout(() => {
      this.proxyReady = true;
    }, 1000);
  },
};
</script>

<style>
/* Global styles from styles.css will apply across all components */
h1 {
  color: #42b983;
}
</style>